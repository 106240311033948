<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="md-2">
      <b-col>
        <b-row>
          <h2 class="purple bold">Portaria</h2>
          <h3 class="tags_count">{{ total }}</h3>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col>
        <b-form-group label="Ciclo">
          <b-form-select
            class=""
            size="sm"
            v-model="selected"
            :options="cycles"
            @change="selectedCycle"
          >
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Buscar aluno">
          <b-form-input
            type="search"
            size="sm"
            placeholder="Digite aqui"
            v-model="search"
            @change="searched"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align="center" v-if="loading">
      <b-col class="mt-5">
        <b-spinner variant="success" label="Carregando..."></b-spinner>
      </b-col>
    </b-row>

    <b-row v-if="!total" align="center">
      <b-col>
        <h4>Não existem dados a mostrar.</h4>
      </b-col>
    </b-row>

    <b-row v-if="!loading">
      <b-col class="mb-1" v-for="(item, index) in students" :key="index">
        <button class="student-button" @click="InfoModal(item)">
          <img
            :src="
              item.User.photo
                ? item.User.photo
                : require('./../../assets/images/defaultstudent.png')
            "
            fluid
            class="avatar-student"
          />

          <p class="name-student">{{ item.User.name }}</p>
        </button>
      </b-col>

      <b-modal
        v-model="modalShow"
        v-if="infoStudent != undefined"
        title="Saída"
        size="lg"
        hide-footer
        @hidden="clean"
      >
        <b-row align="center">
          <b-col>
            <b-col>
              <div class="avatar-student mb-3">
                <b-img
                  :src="
                    infoStudent.photo
                      ? infoStudent.photo
                      : require('./../../assets/images/defaultstudent.png')
                  "
                  class="avatar-student"
                />
              </div>

              <p class="text-modal">
                <b> {{ infoStudent.name }} </b>
              </p>
              <p class="name-modal">{{ infoStudent.cycle }}</p>
              <p class="text-modal">Turma: {{ infoStudent.classroom }}</p>
              <br />

              <b-card class="text-modal">
                <b-card-body>
                  <p>
                    <b> Responsáveis/Contratantes </b>
                  </p>
                  <b-card-header>
                    <b-row>
                      <b-col
                        v-for="(
                          item, index
                        ) in infoStudent.EnrollmentResponsibles"
                        :key="index"
                      >
                        <div class="avatar-student mb-3">
                          <img
                            :src="
                              item.photo
                                ? item.photo
                                : require('./../../assets/images/defaultstudent.png')
                            "
                            class="avatar-student"
                          />
                        </div>
                        <p class="text-modal">
                          {{ item.relationship ? item.relationship : "---" }}
                        </p>
                        <p class="name-modal">
                          <b> {{ item.name ? item.name : "---" }} </b>
                        </p>
                        <p class="text-modal">
                          Telefone 1: {{ item.phone1 ? item.phone1 : "---" }}
                          <br />
                          Telefone 2: {{ item.phone2 ? item.phone2 : "---" }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <br />

                  <p>
                    <b> Contato de emergência:</b>
                  </p>

                  <b-card-header>
                    <b-row>
                      <b-col>
                        {{ infoStudent.contact1 }}
                        <br />
                        {{ infoStudent.contact1_phone }}
                      </b-col>
                      <br />
                      <b-col>
                        {{ infoStudent.contact2 }}
                        <br />
                        {{ infoStudent.contact2_phone }}
                      </b-col>
                    </b-row>
                  </b-card-header>

                  <br />

                  <p>
                    <b> Acompanhantes autorizados </b>
                  </p>
                  <b-card-header>
                    <b-row>
                      <b-col
                        v-for="(
                          item, index
                        ) in infoStudent.EnrollmentComplementContacts"
                        :key="index"
                      >
                        <div class="avatar-student mb-3">
                          <img
                            :src="
                              item.photo
                                ? item.photo
                                : require('./../../assets/images/defaultstudent.png')
                            "
                            class="avatar-student"
                          />
                        </div>
                        <p class="text-modal">
                          {{ item.relationship ? item.relationship : "---" }}
                        </p>
                        <p class="name-modal">
                          <b> {{ item.name ? item.name : "---" }} </b>
                        </p>
                        <p class="text-modal">
                          Telefone 1: {{ item.number1 ? item.number1 : "---" }}
                          <br />
                          Telefone 2: {{ item.number2 ? item.number2 : "---" }}
                        </p>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <br />
                  <p>
                    <b> Autorizações </b>
                  </p>
                  <b-card-header>
                    <b-row>
                      <b-col
                        v-for="(
                          item, index
                        ) in infoStudent.filter_authorizations"
                        :key="index"
                      >
                        <p class="text-modal">{{ item.name }}</p>
                      </b-col>
                    </b-row>
                  </b-card-header>
                </b-card-body>
              </b-card>
            </b-col>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-if="total"
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @change="changeCurrentPage"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import HttpRda from "../../http-rda";

export default {
  name: "concierge",
  components: {},
  data() {
    return {
      perPage: 30,
      currentPage: 1,
      total: null,
      loading: false,
      search: null,
      selected: 0,
      modalShow: false,
      cycles: [],
      students: [],
      data: [],
      infoStudent: [],
      breadcrumb: [
        {
          text: "Portaria",
          href: "/portaria",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.total;
    },
  },
  methods: {
    searched(value) {
      this.search = value;
      this.changeCurrentPage(1);
    },

    selectedCycle(value) {
      this.selected = value;
      this.changeCurrentPage(1);
    },
    changeCurrentPage(page) {
      this.currentPage = page;
      this.perPage = 30;
      let params = {
        page: page,
        cycle: this.selected,
        search: this.search,
        limit: this.perPage,
      };

      if (this.selected === 0) {
        delete params.cycle;
      }

      if (this.search) {
        delete params.limit;
        this.perPage = 50;
      }

      this.GetAllStudents(params);
    },

    async GetAllStudents(params) {
      this.loading = true;

      await HttpRda.get(`/studentsforconcierge/?include`, { params })
        .then((response) => {
          let data = response.data.students;
          data = data.filter((item) => item.User !== null);
          this.students = data;
          this.data = data;
          this.loading = false;
          this.total = response.data.count;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    GetStudents() {
      const params = {
        limit: this.perPage,
        cycle: this.selected,
        page: this.currentPage,
      };

      if (
        this.selected === 0 ||
        this.selected === null ||
        this.selected === undefined
      ) {
        this.currentPage = 1;
        delete params.cycle;
        this.GetAllStudents(params).then;
      } else {
        this.GetAllStudents(params);
      }
    },

    GetCycles() {
      HttpRda.get(`/cycles`).then((response) => {
        let data = response.data.cycles;
        data.map((item) => {
          item.text = item.name;
          item.value = item.id;
        });
        data.push({
          text: "Todos",
          value: 0,
        });
        this.cycles = data;
      });
    },

    GetClassRoom(id) {
      const classroom = HttpRda.get(`/classrooms/${id}`);
      return classroom;
    },
    GetEnrollment(id) {
      const enrollment = HttpRda.get(`/enrollments/${id}`);
      return enrollment;
    },

    async InfoModal(data) {
      const enrollment = await this.GetEnrollment(data.id);
      const classroom = data.classroom_id
        ? await this.GetClassRoom(data.classroom_id)
        : null;

      const cycle = this.cycles.find((item) => item.id == data.cycle_id);
      const { name, photo } = data.User;

      let EnrollmentComplementContacts = [];
      let contact1 = null;
      let contact1_phone = null;
      let contact2 = null;
      let contact2_phone = null;

      if (enrollment.data.enrollment?.EnrollmentComplement) {
        EnrollmentComplementContacts =
          enrollment.data.enrollment.EnrollmentComplement
            .EnrollmentComplementContacts;
        contact1 = enrollment.data.enrollment.EnrollmentComplement.contact1;
        contact1_phone =
          enrollment.data.enrollment.EnrollmentComplement.contact1_phone;
        contact2 = enrollment.data.enrollment.EnrollmentComplement.contact2;
        contact2_phone =
          enrollment.data.enrollment.EnrollmentComplement.contact2_phone;
      }

      let { EnrollmentAuthorization, EnrollmentResponsibles } =
        enrollment.data.enrollment;

      const authorizations = [
        {
          name: "Excursões",
          value: EnrollmentAuthorization
            ? EnrollmentAuthorization.excursion
            : null,
        },
        {
          name: "Recreio",
          value: EnrollmentAuthorization
            ? EnrollmentAuthorization.leave_school_break
            : null,
        },
        {
          name: "Banho",
          value: EnrollmentAuthorization
            ? EnrollmentAuthorization.shower
            : null,
        },
        {
          name: "Antecipada",
          value: EnrollmentAuthorization
            ? EnrollmentAuthorization.leave_school_antecipated
            : null,
        },
      ];
      const filter_authorizations = authorizations.filter(
        (item) => item.value == true
      );

      const responsiblesAux = [];

      if (EnrollmentComplementContacts.length > 0) {
        for (
          let index = 0;
          index < EnrollmentComplementContacts.length;
          index++
        ) {
          responsiblesAux.push({
            name: EnrollmentComplementContacts[index].name,
            photo: EnrollmentComplementContacts[index].photo,
            relationship: EnrollmentComplementContacts[index].relationship,
            number1: EnrollmentComplementContacts[index].number1 || "",
            number2: EnrollmentComplementContacts[index].number2 || "",
          });
        }
      }

      if (EnrollmentResponsibles.length > 0) {
        for (let index = 0; index < EnrollmentResponsibles.length; index++) {
          responsiblesAux.push({
            name: EnrollmentResponsibles[index].name,
            photo: EnrollmentResponsibles[index].photo,
            relationship: EnrollmentResponsibles[index].relationship,
            number1: EnrollmentResponsibles[index].phone1 || "",
            number2: EnrollmentResponsibles[index].phone2 || "",
          });
        }
      }

      const responsibles = [];

      for (let index = 0; index < responsiblesAux.length; index++) {
        let foundElement = responsibles.find(
          (item) => item.name === responsiblesAux[index].name
        );

        if (!foundElement) {
          responsibles.push(responsiblesAux[index]);
        }
      }

      this.infoStudent = {
        name,
        photo,
        cycle: cycle.designation,
        classroom:
          classroom !== null
            ? classroom.data.classroom.designation
            : "Sem Turma",
        filter_authorizations,
        EnrollmentComplementContacts: responsibles,
        EnrollmentResponsibles,
        contact1,
        contact1_phone,
        contact2,
        contact2_phone,
      };
      this.modalShow = !this.modalShow;
    },

    clean() {
      this.infoStudent = [];
    },
  },

  async created() {
    try {
      this.loading = true;
      await Promise.all([this.GetCycles(), this.GetStudents()]);
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style>
.tags_count {
  background-color: #68c5b7;
  border-radius: 3px;
  color: #fff;
  margin-left: 10px;
  padding: 3px 10px;
  height: max-content;
}
.avatar-student {
  width: 100px;
  max-width: 100px;
  height: 100px;
  max-height: 100px;
  border-radius: 50%;
  margin: 0;
}

.student-button {
  cursor: pointer;
  border: none;
  background: none;
}

.name-student {
  font-size: 0.8rem;
  color: #828282;
  letter-spacing: 0ch;
}

.text-modal {
  margin: 0;
  font-size: 1rem;
  color: #828282;
}

.name-modal {
  margin: 0;
  font-size: 0.9rem;
  color: #828282;
}
</style>
